import React, {Component} from 'react';
import { connect } from 'react-redux';
import ckGlobals from 'ckGlobals';
import { TOGGLE_SEARCH } from './common/action-types';
import { translate } from './common/common';
import '../scss/searcher.scss';

const mapStateToProps = state => {
    return { 
        isSearchOpen: state.isSearchOpen
    };
}

const mapDispatchToProps = dispatch => {
    return {
        toggleSearch: () => dispatch({ type: TOGGLE_SEARCH })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(
    class Searcher extends Component {
        constructor(props) {
            super(props);
            this.toggleSearch = this.toggleSearch.bind(this);
        }

        toggleSearch() {
            this.props.toggleSearch();
        }

        componentDidMount() {
            //this.textInput.focus();
        }

        render() {
            const { site_title, site_url, q } = ckGlobals;
            let { isSearchOpen } = this.props;
            isSearchOpen = isSearchOpen || (q != null && q != '');
            const searcherClass = isSearchOpen? 'open' : '',
                  searcherTool = isSearchOpen? 
                    <div itemscope itemtype="http://schema.org/WebSite">
                        <meta itemprop="name" content={site_title} />
                        <meta itemprop="url" content={site_url} />
                        <form action="search" id="main-search" method="get" itemprop="potentialAction" itemscope itemtype="http://schema.org/SearchAction">
                            <meta itemprop="target" content={`${site_url}search?q=${q}`} />
                            <input itemprop="query-input" type="text" name="q" placeholder={translate('search-input-label')} required />
                            <button type="submit" form="main-search" title={translate('search-btn')}>
                                <i class="fas fa-search"></i>
                            </button>
                        </form>
                    </div>
                    :
                    <i class="fas fa-search" onClick={this.toggleSearch}></i>;
            return(
                <div className={`search-console ${searcherClass}`}>
                    {searcherTool}
                </div>
            );
        }
    }
);
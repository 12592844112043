import { AJAX_ERROR, THROW_ALERT, FETCHED_DONE, FETCH_LANG, TOGGLE_SEARCH, TOGGLE_MOB_NAV } from './action-types';
import ckMenu from 'ckMenu';

const initialState = {
    errors: [],
    alerts: [],
    fetchedUrls: [],
    service: './ajax_index.php',
    isSearchOpen: false,
    isMobNavOpen: false,
    ckMenu: ckMenu,
    ckLang: null
};

function MainReducer(state = initialState, action) {
    switch(action.type) {
        case AJAX_ERROR:
            let { errors } = state, 
                newErrors = [];
            newErrors = errors.map((error) => error);
            newErrors.push(action.error);
            return Object.assign({}, state, { errors: newErrors });
        case THROW_ALERT:
            let { alerts } = state,
                newAlerts = [];
            newAlerts = alerts.map((alert) => alert);
            newAlerts.push(action.alert);
            return Object.assign({}, state, { alerts: newAlerts });
        case FETCHED_DONE:
            let { fetchedUrls } = state,
                newFUs = [];
            newFUs = fetchedUrls.map((fu) => fu);
            newFUs.push(action.fetchResult);
            return Object.assign({}, state, { fetchedUrls: newFUs });
        case FETCH_LANG:
            return Object.assign({}, state, { ckLang: action.lang });
        case TOGGLE_SEARCH:
            return Object.assign({}, state, { isSearchOpen: !state.isSearchOpen });
        case TOGGLE_MOB_NAV:
            return Object.assign({}, state, { isMobNavOpen: !state.isMobNavOpen });
        default:
            return state;
    }
};

export default MainReducer;
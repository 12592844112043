import React, {Component} from 'react';
import { connect } from 'react-redux';
import { TOGGLE_MOB_NAV } from './common/action-types';
import Searcher from './searcher'
import ckGlobals from 'ckGlobals';
import '../scss/header.scss';

const mapStateToProps = state => {
    return { 
        isMovNavOpen: state.isMobNavOpen
    };
}

const mapDispatchToProps = dispatch => {
    return {
        toggleMobNav: () => dispatch({ type: TOGGLE_MOB_NAV })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(
    class Header extends Component {
        constructor(props) {
            super(props);
            this.toggleMobNav = this.toggleMobNav.bind(this);
        }

        toggleMobNav() {
            this.props.toggleMobNav();
        }

        render() {
            const { site_name, site_title, site_url, site_desc, user } = ckGlobals,
                uProfile = (typeof user != 'undefined' && user != null)? 
                    <a href="/profile"><i class="glyphicon glyphicon-user"></i></a> : '';
            return(
                <header>
                    <div class="wrapper-big">
                        <div itemscope itemtype="http://schema.org/Organization">
                        <meta itemprop="name" content={site_title} />
                        <meta itemprop="mainEntityOfPage" content={site_url} />
                        <meta itemprop="description" content={site_desc} />
                        <meta itemprop="sameAs" content="https://www.facebook.com/web.ekiria/" />
                        <meta itemprop="sameAs" content="https://twitter.com/web_ekiria" />
                        <div class="invisible" itemprop="founder" itemscope itemtype="http://schema.org/Person">
                            <meta itemprop="name" content="D'Arath" />
                            <meta itemprop="gender" content="http://schema.org/Male" /> 
                            <meta itemprop="email" content="darath@ekiria.net" />
                        </div>
                        <a itemprop="url" id="logo" href="./">
                            <img itemprop="logo" src="imgs/logo-ekiria-header.png" alt={site_name} /></a>
                        </div>
                        <div class="navbar-header">
                            <Searcher />
                            {uProfile}
                            <button type="button" class="navbar-toggle collapsed"
                              onClick={this.toggleMobNav}
                              data-toggle="collapse" data-target="#sidebar" aria-expanded="false">
                                <i class="fas fa-bars"></i>
                            </button>
                        </div>
                    </div>
                </header>
            );
        }
    }
)
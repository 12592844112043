import React, {Component} from 'react';
import { translate } from './common/common';
import '../scss/footer.scss';

export default class MainBody extends Component {
    render() {
        const today = new Date();
        return (
            <footer>
                <div className="wrapper-big">
                    Ekiria {today.getFullYear()} - {translate('footer-all-rights-reserved')}
                </div>
            </footer>
        );
    }
}
import React, {Component} from 'react';
import { connect } from 'react-redux';
import { TOGGLE_MOB_NAV } from './common/action-types';
import ckMenu from 'ckMenu';
import '../scss/menu.scss';

const mapStateToProps = state => {
    return { 
        isMobNavOpen: state.isMobNavOpen
    };
}

const mapDispatchToProps = dispatch => {
    return {
        toggleMobNav: () => dispatch({ type: TOGGLE_MOB_NAV })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(
    class Menu extends Component {
        constructor(props) {
            super(props);
            this.toggleMobNav = this.toggleMobNav.bind(this);
        }

        toggleMobNav() {
            this.props.toggleMobNav();
        }

        displayItem(item) {
            const dItem = (typeof item.url != 'undefined' && item.url != null && item.url != '')?
                <a href={item.url}>{item.label}</a> :
                item.label;
            return dItem;
        }

        displayMenu(level) {
            return(
                <ul>
                    {ckMenu.map((mitem, mix) => {
                        const mchildren  = (typeof mitem.children != 'undefined')?
                            <ul>
                                {mitem.children.map((citem, cix) => <li>{this.displayItem(citem)}</li>)}
                            </ul>
                            : null;
                        return <>
                                <li>{this.displayItem(mitem)}{mchildren}</li>
                            </>;
                    })}
                </ul>
            );
        }

        render() {
            const navClass = this.props.isMobNavOpen? 'navOpen' : '';
            return(
                <nav className={navClass}>
                    <div id="navCloser"><i class="fas fa-times" onClick={this.toggleMobNav}></i></div>
                    {this.displayMenu()}
                </nav>
            );
        }
    }
);
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './common/store';
import CKApp from './ckApp.js';
import '../scss/ck-main.scss';

ReactDOM.render(
    <Provider store={store}>
        <CKApp />
    </Provider>,
    document.getElementById('ckApp')
);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FETCH_LANG } from './common/action-types';
import ckGlobals from 'ckGlobals';
import Header from './header';
import Menu from './menu';
import MainBody from './mainbody';
import SideBar from './sidebar';
import Footer from './footer';

const mapStateToProps = state => {
   return { 
       ckLang: state.ckLang,
       service: state.service
   };
}

const mapDispatchToProps = dispatch => {
   return {
      fetchLang: (data) => dispatch({ type: FETCH_LANG, lang: data })
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(
   class CKApp extends Component{
      constructor(props) {
         super(props);
         this.fetchLang = this.fetchLang.bind(this);
     }

      fetchLang() {
         const { lang } = ckGlobals;
         fetch(`${this.props.service}?sys[action]=get_lang&lang=${lang}`, {
            headers: { "Content-Type": "application/json; charset=utf-8" },
            method: 'GET'
         })
            .then(response => response.json())
            .then((data) => {
               //console.log(data);
               this.props.fetchLang(data)
            });
     }

     componentDidMount() {
        this.fetchLang();
     }

      render(){
         return(
            <>
               <Header />
               <Menu />
               <section id="body">
                  <MainBody />
                  <SideBar />
               </section>
               <Footer />
            </>
         );
      }
   }
);
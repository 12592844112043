import React, {Component} from 'react';
//import { connect } from 'react-redux';
import Loadable from 'react-loadable';
import Spinner from './Spinner';
import ckGlobals from 'ckGlobals';
import '../scss/mainbody.scss';

export default //connect(mapStateToProps)(
    class MainBody extends Component {
        constructor(props) {
            super(props);
        }

        render() {
            let { action } = ckGlobals, 
                MainView = null;
            /*switch(action) {
                case 'content':
                    action = 'get_content';
                    break;
                default:
                    //do nothing
            }*/
            //console.log(`action: ${action}`);
            MainView = Loadable({
                loader: () => import('./' + action),
                loading: Loading
            });
            return (
                <section id="main">
                    <article className="main-wrapper">
                        <MainView />
                    </article>
                </section>
            );
        }
    }
//);

function Loading({ error }) {
    if(error) {
        console.log(error);
        return (
            <div>Error!</div>
        )
    }
    return(
        <Spinner />
    )
}
import React, {Component, useImperativeHandle} from 'react';
import { translate } from './common/common';
import '../scss/sidebar.scss';

export default class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "usuario",
            pwd: "password",
            loginError: ""
          }
          this.handleChange = this.handleChange.bind(this);
          this.handleSubmit = this.handleSubmit.bind(this);
      }


    componentDidMount() {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    async handleSubmit(event) {
        event.preventDefault();
        const res = await fetch(`ajax_index.php?sys[action]=login_validate&pwd=${this.state.pwd}&email=${this.state.email}`)
        .then(response => response.json())
        .then((data) => {
            data.registration !=='access_granted'? this.setState({ loginError: data.message }): location.reload();
        });      }

    render() {
        return (
            <section id="sidebar">
                <div class="sidebar-wrapper">
                <div class="sm">
                        <h3>{translate('user-login-title')}</h3>
                        <div>
                        <form id="login" method="post" onSubmit={this.handleSubmit}>
                            <input type="hidden" name="sys[action]" value="login_validate" />
                            <span class="valerror" id="result">{this.state.loginError}</span>
                            <label for="email">Correo electrónico</label>
                            <input type="text" id="email" name="email" onChange={e=>this.handleChange(e)} value={this.state.email}/>
                            <label for="pwd">Contraseña</label>
                            <input type="password" name="pwd" id="pwd" onChange={e=>this.handleChange(e)} value={this.state.pwd}/>
                            <p class="center"><input type="submit" value="Acceder" /></p>
                            <p class="center form-alternative"><a href="cms_pwd_recover_form">{translate('olvidasteS-password')}</a></p>
                        </form>
                        </div>
                    </div>                    
                    <div class="sm">
                        <h3>{translate('follow-us')}</h3>
                        <div class="fb-like" 
                            data-href="https://www.facebook.com/web.ekiria/" 
                            data-width="280" 
                            data-layout="button_count" 
                            data-action="like"
                            data-size="large" 
                            data-show-faces="false" 
                            data-share="false"></div>
                        <div class="tw-follow">
                            <a href="https://twitter.com/web_ekiria?ref_src=twsrc%5Etfw" 
                                className="twitter-follow-button" 
                                data-size="large" 
                                data-show-count="false">{translate('gi-follow')} @web_ekiria</a>
                        </div>
                    </div>
                    <div>
                        <h3>{translate('ads-title')}</h3>
                        <ins className="adsbygoogle" 
                            style={{ display: 'block' }} 
                            data-ad-client="ca-pub-3981059158564224" 
                            data-ad-slot="9658381339" 
                            data-ad-format="auto">
                        </ins>
                    </div>
                </div>
            </section>
        );
    }
}